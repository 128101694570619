<!--  -->
<template>
	<div class="echarts-item">
		<div class="item-header">企业到期提醒</div>
		<div class="item-content">
			<!-- 滚动列表 -->
			<ul class="company-ul" v-if="liData&&liData.length>0">
        <vue-seamless-scroll
              :data="liData"
              class="seamless-warp"
              :class-option="optionSetting"
            >
            <li
                class="li_item"
                v-for="item in liData"
                :key="item.id"
              >
              <span>{{item.name}}</span>
              <span>还有{{item.value}}天即将到期</span>
              </li>
            </vue-seamless-scroll>
			</ul>
      <div class="noData" v-else>
        <span>--暂无数据--</span>
      </div>
		</div>
	</div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
	name: 'ExpireItem',
  props: {
    dataList: Array
  },
	//import引入的组件需要注入到对象中才能使用
	components: {},
	data() {
		//这里存放数据
		return {
			liData: this.dataList,
		};
	},
  computed: {
    optionSetting() {
      return {
        step: 0.15, // 数值越大速度滚动越快
        limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
	//生命周期 - 创建完成（可以访问当前this实例）
	created() {},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted() {
  },
	//方法集合
	methods: {},
};
</script>
<style lang="scss" scoped>
//@import url(); 引入公共css类
</style>
