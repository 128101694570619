<!--  -->
<template>
	<div class="echarts-item">
		<div class="item-header">工单数量排行</div>
		<div class="item-content item-height">
			<div class="item-search">
				<span :class="isActive === 1 ? 'active' : ''" @click="handleSearch(1)">总工单数</span>
				<span :class="isActive === 2 ? 'active' : ''" @click="handleSearch(2)">昨日工单</span>
				<span :class="isActive === 3 ? 'active' : ''" @click="handleSearch(3)">一周工单</span>
			</div>
			<div class="item-small-content" v-if="isShow">
				<div id="OrderCharts" :style="{ width: '100%', height: '100%' }"></div>
			</div>
      <div class="noData" v-else>
        <span>--暂无数据--</span>
      </div>
		</div>
	</div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
const echartsText = '#CBCBCB';
export default {
	name: 'OrderNumberItem',
	//import引入的组件需要注入到对象中才能使用
	props: {
		dataObj: Object,
	},
	components: {},
	data() {
		//这里存放数据
		return {
			isActive: 1,
      isShow: true,
			options: {
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'shadow',
					},
				},
				legend: {},
				grid: {
					left: '3%',
					right: '4%',
					bottom: '3%',
					top: '2%',
					containLabel: true,
				},
				xAxis: {
					type: 'value',
					boundaryGap: [0, 0.01],
					splitLine: {
						// 是否显示分隔线
						show: false,
					},
					axisLabel: {
						//是否显示刻度标签
						show: false,
					},
					axisLine: {
						// 是否显示坐标轴轴线
						show: false,
					},
					axisTick: {
						//是否显示坐标轴刻度
						show: false,
					},
				},
				yAxis: {
					type: 'category',
					splitLine: {
						// 是否显示分隔线
						show: false,
					},
					minorTick: {
						show: false,
					},
					axisLine: {
						// 是否显示坐标轴轴线
						show: false,
					},
					axisTick: {
						//是否显示坐标轴刻度
						show: false,
					},
					axisLabel: {
						//是否显示刻度标签
						show: true,
						textStyle: {
							color: echartsText,
						},
					},
					data: [],
				},
				series: [
					{
						type: 'bar',
						barWidth: 15,
						showBackground: true,
						backgroundStyle: {
							color: '#062C46',
						},
						itemStyle: {
							emphasis: {},
							normal: {
								color: new this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [
									{ offset: 0, color: '#0163EB' },
									{ offset: 1, color: '#00BCF9' },
								]),
							},
						},
						data: [],
					},
				],
			},
		};
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created() {},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted() {
		// this.drawEcharts();
	},
	//方法集合
	methods: {
		drawEcharts() {
			let myChart = this.$echarts.init(document.getElementById('OrderCharts'));
			let yData = [];
			let xData = [];
			if (this.isActive === 1) {
				let name = this.dataObj.orderCountTotal.map((el) => {
					return el.name;
				}); // y轴为name
				let value = this.dataObj.orderCountTotal.map((el) => {
					return el.value;
				}); // x轴为value
				yData = name.filter((el, index) => {
					return index !== 0;
				});
				xData = value.filter((el, index) => {
					return index !== 0;
				});
        this.isShow = (xData&&xData.length>0) ? true : false
			} else if (this.isActive === 2) {
				let name = this.dataObj.orderCountYesterday.map((el) => {
					return el.name;
				}); // y轴为name
				let value = this.dataObj.orderCountYesterday.map((el) => {
					return el.value;
				}); // x轴为value

				yData = name.filter((el, index) => {
					return index !== 0;
				});
				xData = value.filter((el, index) => {
					return index !== 0;
				});
        this.isShow = (xData&&xData.length>0) ? true : false
			} else {
        let name = this.dataObj.orderCountLastWeek.map((el) => {
					return el.name;
				}); // y轴为name
				let value = this.dataObj.orderCountLastWeek.map((el) => {
					return el.value;
				}); // x轴为value

				yData = name.filter((el, index) => {
					return index !== 0;
				});
				xData = value.filter((el, index) => {
					return index !== 0;
				});
        this.isShow = (xData&&xData.length>0) ? true : false
			}
			this.options.yAxis.data = this.handleSort(
				yData.filter((el, index) => {
					return index < 5;
				})
			);
			this.options.series[0].data = this.handleSort(
				xData.filter((el, index) => {
					return index < 5;
				})
			);
			myChart.setOption(this.options);
			myChart.resize();
			return myChart;
		},

		handleSearch(v) {
			this.isActive = v;
			this.drawEcharts();
		},

		// 倒序
		handleSort(arr) {
			var temp;
			for (let i = 0; i < arr.length / 2; i++) {
				temp = arr[i];
				arr[i] = arr[arr.length - 1 - i];
				arr[arr.length - 1 - i] = temp;
			}
			return arr;
		},
	},
	watch: {
		dataObj: {
			handler() {
				this.drawEcharts();
			},
		},
	},
};
</script>
<style lang="scss" scoped>
//@import url(); 引入公共css类
</style>
