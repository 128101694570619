<!--  -->
<template>
	<div class="echarts-item">
		<div class="item-header item-long">项目平均单耗排行</div>
		<div class="item-content item-height">
			<div class="item-typeSearch">
				<div>
					<span :class="isActive === 0 ? 'active' : ''" @click="handleSearch(0)">昨日</span>
					<span :class="isActive === 1 ? 'active' : ''" @click="handleSearch(1)">上一个小时</span>
				</div>
				<div>
					<el-select v-model="selectOne" placeholder="请选择" @change="handleOneIndustry" style="width: 180px; margin-right: 10px">
						<el-option v-for="item in oneLevel" :key="item.id" :label="item.name" :value="item.id"> </el-option>
					</el-select>
					<el-select v-model="selectTwo" placeholder="请选择" @change="handleTwoIndustry" style="width: 180px">
						<el-option v-for="item in twoLevel" :key="item.id" :label="item.name" :value="item.id"> </el-option>
					</el-select>
				</div>
			</div>
			<div class="item-small-content" v-if="isShow">
				<div id="UnitCharts" :style="{ width: '100%', height: '100%' }"></div>
			</div>
      <div class="noData" v-else>
        <span>--暂无数据--</span>
      </div>
		</div>
	</div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import Industry from '@/assets/json/Industry.json';

import api from '@/api/home';

const echartsText = '#CBCBCB';
export default {
	name: 'UnitItem',
	//import引入的组件需要注入到对象中才能使用
	components: {},
	data() {
		//这里存放数据
		return {
			isActive: 0,
			isShow: true,
			selectOne: '',
			selectTwo: '',
			oneLevel: [],
			twoLevel: [],
			industryArr: [],
			options: {
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'shadow',
					},
					formatter: function (params) {
						return `
            <p>项目名称：${params[0].axisValue ? params[0].axisValue : ''}</p>
            <p>所属企业：${params[0].data && params[0].data.name ? params[0].data.name : ''}</p>
            <p>单耗值：${params[0].value !== '' ? params[0].value : 0}</p>`;
					},
				},
				grid: {
					left: '3%',
					right: '4%',
					bottom: '3%',
					top: '2%',
					containLabel: true,
				},
				xAxis: [
					{
						type: 'category',
						data: [],
						axisLabel: {
							textStyle: {
								color: echartsText,
							},
						},
					},
				],

				yAxis: [
					{
						type: 'value',
						splitLine: {
							// 是否显示分隔线
							show: true,
							lineStyle: {
								color: '#0F1B2F',
							},
						},
						axisLabel: {
							//是否显示刻度标签
							show: false,
							textStyle: {
								color: echartsText,
							},
						},
						axisTick: {
							//是否显示坐标轴刻度
							show: false,
						},
					},
				],
				series: [
					{
						type: 'bar',
						barWidth: 20,
						itemStyle: {
							color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
								{ offset: 0, color: '#FEDD00' },
								{ offset: 1, color: '#FF9C00' },
							]),
						},
						data: [],
					},
				],
			},
		};
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created() {
		this.oneLevel = Industry.districts.map((el) => {
			return {
				name: el.name,
				id: el.id,
			};
		});
		this.init();
	},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted() {},
	//方法集合
	methods: {
		// 初始化选择
		async init() {
			const { data } = await api.industryMore('air');
			this.industryArr = data;
			if (this.industryArr && this.industryArr.length > 0) {
				this.handleOneIndustry(this.industryArr[0]);
				this.selectOne = this.industryArr[0];
				this.selectTwo = this.industryArr[1];
				this.drawEcharts();
			}
		},

		async drawEcharts() {
			const { data } = await api.industryData('air', { type: this.isActive === 0 ? 'YESTERDAY' : 'LAST_HOUR', industryId: this.selectTwo });
			let xData = data.map((el) => {
				return el.projectName;
			});
			let yData = data.map((el) => {
				return {
					name: el.company,
					value: el.consumption,
				};
			});
      
			this.options.xAxis[0].data = xData.filter((el, index) => {
				return index < 12;
			});
			this.options.series[0].data = yData.filter((el, index) => {
				return index < 12;
			});
			let myChart = this.$echarts.init(document.getElementById('UnitCharts'));
      this.isShow = (xData&&xData.length>0) ? true : false
			myChart.setOption(this.options);
			myChart.resize();
			return myChart;
		},
		// 选中一级
		handleOneIndustry(v) {
			let selectObj = Industry.districts.filter((el) => {
				return el.id === v;
			});
			this.twoLevel = selectObj[0].districts.map((el) => {
				return {
					name: el.name,
					id: el.id,
				};
			});
		},
		// 选中二级
		handleTwoIndustry(v) {
			this.selectTwo = v;
			this.drawEcharts();
		},
		// tabs切换
		handleSearch(v) {
			this.isActive = v;
			this.drawEcharts();
		},
	},
};
</script>
<style lang="scss" scoped>
//@import url(); 引入公共css类
</style>
