<!--  -->
<template>
	<div class="all_data">
		<div class="charts_map">
			<other-map :type="'CUSTOMER'"/>
      
		</div>
		<div class="company_data">
			<div class="company_sub">
				<div class="echarts_title">企业订阅量</div>
				<div id="subscription" :style="{ width: '100%', height: '290px' }"></div>
			</div>
			<div class="company_daily">
				<div class="echarts_title">平台日活量</div>
				<div id="daily" :style="{ width: '100%', height: '290px' }"></div>
			</div>
			<div class="company_duration">
				<div class="echarts_title">平台使用时长</div>
				<div id="duration" :style="{ width: '100%', height: '290px' }"></div>
			</div>
		</div>
	</div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import api from '@/api/home';
import OtherMap from '../EchartsData/OtherMap.vue';

const echartsText = '#CBCBCB';
export default {
	//import引入的组件需要注入到对象中才能使用
	components: {OtherMap },
	data() {
		//这里存放数据
		return {
			subscriptionObj: {
				name: [],
				value: [],
			},
      dailyList: [],
      appUseDuration:[],
			optionPie: {
				tooltip: {
					trigger: 'item',
				},
				legend: {
					top: '20',
					left: 'center',
					textStyle: {
						color: echartsText,
					},
				},
				color: ['#15D0FF', '#FB7E33', '#FFDB15', '#04F5D5'],
				series: [
					{
						type: 'pie',
						radius: ['40%', '60%'],
						center: ['50%', '60%'],
						avoidLabelOverlap: false,
						label: {
							show: false,
							position: 'center',
						},
						emphasis: {
							label: {
								show: true,
								fontSize: '14',
								fontWeight: 'bold',
								color: echartsText,
							},
						},
						labelLine: {
							show: false,
						},
						data: [],
					},
				],
			},
		};
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created() {},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted() {
		this.init();
	},
	//方法集合
	methods: {
		async init() {
			const { data } = await api.allEchartsData();
			this.subscriptionObj.name = data.subscriptionCount.map((el) => {
				return el.name;
			});
			this.subscriptionObj.value = data.subscriptionCount.map((el) => {
				return el.value;
			});
      this.dailyList = data.pv
      this.appUseDuration = data.appUseDuration.map(el=>{return {...el,value: (el.value/3600).toFixed(2)}})

			this.handleSubscription();
			this.handleDaily();
			this.handleDuration();
		},
		async handleSubscription() {
			let myChart = this.$echarts.init(document.getElementById('subscription'));
			let options = {
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						// 鼠标悬停
						type: 'shadow',
					},
				},
				legend: {},
				grid: {
					left: '3%',
					right: '4%',
					bottom: '3%',
					top: '2%',
					containLabel: true,
				},
				xAxis: {
					type: 'value',
					boundaryGap: [0, 0.01],
					splitLine: {
						// 是否显示分隔线
						show: false,
					},
					axisLabel: {
						//是否显示刻度标签
						show: false,
					},
					axisLine: {
						// 是否显示坐标轴轴线
						show: false,
					},
					axisTick: {
						//是否显示坐标轴刻度
						show: false,
					},
				},
				yAxis: {
					type: 'category',
					splitLine: {
						// 是否显示分隔线
						show: false,
					},
					minorTick: {
						show: false,
					},
					axisLine: {
						// 是否显示坐标轴轴线
						show: false,
					},
					axisTick: {
						//是否显示坐标轴刻度
						show: false,
					},
					axisLabel: {
						//是否显示刻度标签
						show: true,
						textStyle: {
							color: echartsText,
						},
					},
					data: this.handleSort(this.subscriptionObj.name),
				},
				series: [
					{
						type: 'bar',
						barWidth: 15,
						showBackground: true,
						backgroundStyle: {
							color: '#062C46',
						},
						itemStyle: {
							emphasis: {},
							normal: {
								color: new this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [
									{ offset: 0, color: '#129BFF' },
									{ offset: 1, color: '#31FBFB' },
								]),
							},
						},
						data: this.handleSort(this.subscriptionObj.value),
					},
				],
			};
			myChart.setOption(options);
      myChart.resize()
      return myChart;
		},
// 倒序
		handleSort(arr) {
			var temp;
			for (let i = 0; i < arr.length / 2; i++) {
				temp = arr[i];
				arr[i] = arr[arr.length - 1 - i];
				arr[arr.length - 1 - i] = temp;
			}
			return arr;
		},
		handleDaily() {
			let myChart = this.$echarts.init(document.getElementById('daily'));
			this.optionPie.series[0].data = this.dailyList;
			myChart.setOption(this.optionPie);
      myChart.resize()
      return myChart;
		},

		handleDuration() {
			let myChart = this.$echarts.init(document.getElementById('duration'));
			this.optionPie.series[0].data = this.appUseDuration
      this.optionPie.tooltip.formatter = (params)=>{
        console.log(params)
        return `${params.name}: ${params.value}h`
      }
			myChart.setOption(this.optionPie);
      myChart.resize()
      return myChart;
		},
	},
};
</script>
<style lang="scss" scoped>
//@import url(); 引入公共css类
</style>
