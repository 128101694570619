<!--  -->
<template>
	<div class="show_center">
		<div class="center-header">
			<div class="show_crumb" @click="handleToHome()">
				<i class="el-icon-s-operation"></i>
			</div>
			<span>奥立信工业互联网平台</span>
		</div>
		<div class="charts_data">
			<all-data />
			<online-flex />
		</div>
	</div>
</template>

<script>
import AllData from '../components/ShowCenter/AllData.vue';
import OnlineFlex from '../components/ShowCenter/OnlineFlex.vue';
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import store from '../store';
import Enumerable from 'linq';
import { MenuJson } from '@/utils/index';
export default {
	//import引入的组件需要注入到对象中才能使用
	components: { AllData, OnlineFlex },
	data() {
		return {};
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created() {},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted() {},
	//方法集合
	methods: {
		getMenuRoles() {
			const resources = store.getters.self.role.resources.map((r) => r.resource.split(':')[0]);
			const menuRoles = Enumerable.from(resources).distinct().toArray();
			return menuRoles;
		},

		handleToHome() {
			const menuRoles = this.getMenuRoles();
			console.log(this.getHomeRedirect(menuRoles));
			const redirectPath = (this.getHomeRedirect(menuRoles) === '/show' ? '/role' : this.getHomeRedirect(menuRoles)) ?? '/role';
			this.$router.push({
				path: redirectPath,
			});
		},

		getHomeRedirect(serverRole) {
			//平展
			const flat = [];
			//递归查找需要激活的menuItem的id属性
			const visit = (subs) => {
				for (let sub of subs) {
					flat.push(sub);
					if ((sub.items?.length ?? 0) > 0) {
						visit(sub.items);
					}
				}
			};
			visit(MenuJson);

			//目标
			const target = flat.filter((m) => m.link !== undefined && m.link !== null && m.link !== '').find((m) => m.ROLE.some((r) => serverRole.includes(r)));
			//默认跳转链接
			return target?.link ?? undefined;
		},
	},
};
</script>
<style lang="scss" scoped>
//@import url(); 引入公共css类
</style>
