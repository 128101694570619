<!--  -->
<template>
	<div class="online">
		<div class="online-title">
			<span>空压在线</span>
		</div>
		<div class="online-flex">
			<div>
				<!-- 企业用户数量排名 -->
				<users-number-item v-if="flag" :data-list="customCount" />
			</div>
			<div>
				<!-- 数据统计 -->
				<statistics-item v-if="flag" :data-obj="statisCount" />
			</div>
			<div>
				<!-- 功能金牌榜 -->
				<function-item v-if="flag" :data-list="menuDuration" />
			</div>
		</div>
		<div class="online-flex">
			<div>
				<div class="mb-30">
					<!-- 版本应用情况 -->
					<edition-item v-if="flag" :data-list="versionCount" />
				</div>
				<div>
					<!-- 企业到期提醒 -->
					<expire-item v-if="flag" :data-list="customerExpireCount" />
				</div>
			</div>
			<div>
				<propject-map />
			</div>
			<div>
				<div class="mb-30">
					<!-- 设备接入数量排名 -->
					<equipment-number-item v-if="flag" :data-list="machineCount" />
				</div>
				<div>
					<!-- 盒子数量排名 -->
					<box-number-item v-if="flag" :data-list="boxCount" />
				</div>
			</div>
		</div>
		<div class="online-flex1">
			<div>
				<!-- 企业平均单耗排行 -->
				<unit-item />
			</div>
			<div>
				<!-- 工单数量排行 -->
				<order-number-item :data-obj="orderNumber"/>
			</div>
		</div>
	</div>
</template>

<script>
import BoxNumberItem from '../EchartsData/BoxNumberItem.vue';
import EditionItem from '../EchartsData/EditionItem.vue';
import EquipmentNumberItem from '../EchartsData/EquipmentNumberItem.vue';
import ExpireItem from '../EchartsData/ExpireItem.vue';
import FunctionItem from '../EchartsData/FunctionItem.vue';
import OrderNumberItem from '../EchartsData/OrderNumberItem.vue';
import StatisticsItem from '../EchartsData/StatisticsItem.vue';
import UnitItem from '../EchartsData/UnitItem.vue';
import UsersNumberItem from '../EchartsData/UsersNumberItem.vue';
import PropjectMap from '../EchartsData/PropjectMap.vue';
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import api from '@/api/home';

export default {
	//import引入的组件需要注入到对象中才能使用
	components: { StatisticsItem, FunctionItem, EditionItem, ExpireItem, EquipmentNumberItem, BoxNumberItem, UnitItem, OrderNumberItem, UsersNumberItem, PropjectMap },
	data() {
		//这里存放数据
		return {
			customCount: [], // 企业用户数量
			customerExpireCount: [], // 企业到期提醒
			versionCount: [], // 版本应用情况
			statisCount: {}, // 数据统计
			machineCount: [], // 设备接入数量
			boxCount: [], // 盒子数量
			menuDuration: [], // 功能金牌榜
			orderNumber: {
				orderCountTotal: [],
				orderCountYesterday: [],
				orderCountLastWeek: [],
			},
			flag: false,
		};
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created() {
		this.init();
	},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted() {},
	//方法集合
	methods: {
		async init() {
			const { data } = await api.appOverview('air');
			this.customCount = data.customerUserCount;
			this.customerExpireCount = data.customerExpire30Count.filter((el) => {
				return el.value < 30;
			});
			this.versionCount = data.versionApplyCount;
			this.statisCount = data.deviceOverview;
			this.machineCount = data.machineCount;
			this.boxCount = data.distributionCount;
			this.menuDuration = data.menuUseDuration;
			this.orderNumber = {
				orderCountTotal: data.orderCountTotal,
				orderCountYesterday: data.orderCountYesterday,
				orderCountLastWeek: data.orderCountLastWeek,
			};
			this.flag = true;
		},
	},
};
</script>
<style lang="scss" scoped>
//@import url(); 引入公共css类
</style>
