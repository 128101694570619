<!--  -->
<template>
	<div class="echarts-item">
		<div class="echarts-line"></div>
		<div class="statis_content">
			<div class="statis_flex">
				<div class="statis_flex_one">
					<div class="statis_item">
						<p class="number" :title="`${dataObj.guestTotalPower}kW`">
							<span>{{ dataObj.guestTotalPower }}</span
							>kW
						</p>
						<p class="title">平台客有总功率</p>
					</div>
					<div class="statis_item">
						<p class="number" :title="`${dataObj.accessTotalPower}kW`">
							<span>{{ dataObj.accessTotalPower }}</span
							>kW
						</p>
						<p class="title">平台总功率</p>
					</div>
					<div class="statis_item">
						<p class="number" :title="`${dataObj.machineCount}个`">
							<span>{{ dataObj.machineCount }}</span
							>个
						</p>
						<p class="title">平台设备接入总数</p>
					</div>
				</div>
				<div class="statis_flex_two">
					<div class="statis_item">
						<p class="number" :title="`${dataObj.guestTotalVolumeFlow}Nm³/min`">
							<span>{{ dataObj.guestTotalVolumeFlow }}</span
							>Nm³/min
						</p>
						<p class="title">平台客有总容积流量</p>
					</div>

					<div class="statis_item">
						<p class="number" :title="`${dataObj.totalVolumeFlow}Nm³/min`">
							<span>{{ dataObj.totalVolumeFlow }}</span
							>Nm³/min
						</p>
						<p class="title">平台总容积流量</p>
					</div>
					<div class="statis_item">
						<p class="number" :title="`${dataObj.pointCount}个`">
							<span>{{ dataObj.pointCount }}</span
							>个
						</p>
						<p class="title">平台接入点总数</p>
					</div>
				</div>
				<div class="statis_flex_three">
					<div class="statis_item">
						<p class="number" :title="`${dataObj.energySaving}kW`">
							<span>{{ dataObj.energySaving }}</span
							>kW
						</p>
						<p class="title">平台节能量</p>
					</div>
					<div class="statis_item">
						<p class="number" :title="`${dataObj.co2Emission}`">
							<span>{{ dataObj.co2Emission }}</span>
						</p>
						<p class="title">二氧化碳排放量</p>
					</div>
					<div class="statis_item">
						<p class="number" :title="`${dataObj.deviceCount}个`">
							<span>{{ dataObj.deviceCount }}</span
							>个
						</p>
						<p class="title">平台盒子总数</p>
					</div>
				</div>
				<div class="statis_flex_other">
					<div>
						<div id="gaugeOne" :style="{ width: '100%', height: '100%' }"></div>
					</div>
					<div>
						<div id="gaugeTwo" :style="{ width: '100%', height: '100%' }"></div>
					</div>
				</div>
			</div>
		</div>
		<div class="echarts-line"></div>
	</div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
	name: 'StatisticsItem',
	//import引入的组件需要注入到对象中才能使用
	props: {
		dataObj: Object,
	},
	components: {},
	data() {
		//这里存放数据
		return {};
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created() {},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted() {
		this.drawEchartsOne();
		this.drawEchartsTwo();
	},
	//方法集合
	methods: {
		drawEchartsOne() {
			let myChart = this.$echarts.init(document.getElementById('gaugeOne'));
			myChart.setOption({
				// 配置
				series: [
					{
						type: 'gauge',
						min: 0,
						max: this.dataObj.accessTotalPower,
						splitNumber: 2,
						axisLine: {
							lineStyle: {
								width: 10,
								color: [
									[0.3, '#67e0e3'],
									[0.7, '#37a2da'],
									[1, '#fd666d'],
								],
							},
						},
						pointer: {
							itemStyle: {
								color: 'auto',
							},
						},
						axisTick: {
							distance: -5,
							length: 4,
							lineStyle: {
								color: '#fff',
								width: 1,
							},
						},
						splitLine: {
							distance: -5,
							length: 6,
							lineStyle: {
								color: '#fff',
								width: 2,
							},
						},
						axisLabel: {
							color: 'auto',
							distance: -30,
							fontSize: 12,
						},
						title: {
							offsetCenter: [0, '35%'],
							fontSize: 14,
							color: '#BCBCBC',
						},
						detail: { formatter: '{value}', offsetCenter: [0, '55%'], fontStyle: 'oblique', fontSize: 14 },
						data: [{ value: this.dataObj.ownTotalPower, name: '自有总功率' }],
					},
				],
			});
			myChart.resize();
			return myChart;
		},
		drawEchartsTwo() {
			let myChart = this.$echarts.init(document.getElementById('gaugeTwo'));
			myChart.setOption({
				// 配置
				series: [
					{
						type: 'gauge',
						min: 0,
						max: this.dataObj.totalVolumeFlow,
						splitNumber: 2,
						axisLine: {
							lineStyle: {
								width: 10,
								color: [
									[0.3, '#67e0e3'],
									[0.7, '#37a2da'],
									[1, '#fd666d'],
								],
							},
						},
						pointer: {
							itemStyle: {
								color: 'auto',
							},
						},
						axisTick: {
							distance: -5,
							length: 4,
							lineStyle: {
								color: '#fff',
								width: 1,
							},
						},
						splitLine: {
							distance: -5,
							length: 6,
							lineStyle: {
								color: '#fff',
								width: 2,
							},
						},
						axisLabel: {
							color: 'auto',
							distance: -35,
							fontSize: 12,
						},
						title: {
							offsetCenter: [0, '35%'],
							fontSize: 14,
							color: '#BCBCBC',
						},
						detail: { formatter: '{value}', offsetCenter: [0, '55%'], fontStyle: 'oblique', fontSize: 14 },
						data: [{ value: this.dataObj.ownTotalVolumeFlow, name: '自有容积量' }],
					},
				],
			});
			myChart.resize();
			return myChart;
		},
	},
};
</script>
<style lang="scss" scoped>
//@import url(); 引入公共css类
p {
	margin: 0;
}
.echarts-line {
	height: 2px;
	background: url('../../assets/length_border.png') no-repeat center center;
	background-size: 100% 100%;
}
.statis_content {
	width: 100%;
	height: 270px;
	padding: 15px 0;
	margin: 5px 0;
	background: linear-gradient(0deg, #09162f 0%, #07101f 100%);
	.statis_flex {
		width: 100%;
		display: flex;

		.statis_flex_one,
		.statis_flex_two,
		.statis_flex_three {
			width: 140px;
			margin-right: 10px;
		}
		.statis_flex_other {
			flex: 1;
			display: flex;
			& > div {
				flex: 1;
				text-align: center;
			}
		}

		.statis_item {
			width: 100%;
			height: 75px;
			text-align: center;
			margin-bottom: 10px;
			background: url('../../assets/small_border.png') no-repeat center center;
			background-size: 100% 100%;

			.number {
				font-size: 16px;
				color: #059293;
				height: 45px;
				line-height: 50px;
				width: 90%;
        margin: 0 auto;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				span {
					font-weight: bold;
				}
			}
			.title {
				font-size: 12px;
				color: #ffffff;
			}
		}
	}
}
</style>
