<!--  -->
<template>
	<div class="echarts-item">
		<div class="item-header">版本应用情况</div>
		<div class="item-content" v-if="isShow">
			<div id="EditionCharts" :style="{ width: '100%', height: '100%' }"></div>
		</div>
    <div class="noData" v-else>
			<span>--暂无数据--</span>
		</div>
	</div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
const echartsText = '#CBCBCB';
export default {
	name: 'EditionItem',
  props:{
    dataList: Array
  },
	//import引入的组件需要注入到对象中才能使用
	components: {},
	data() {
		//这里存放数据
		return {
      isShow: true,
			optionPie: {
				tooltip: {
					trigger: 'item',
				},
				legend: {
					top: '17%',
					left: '55%',
          orient: 'vertical',
					textStyle: {
						color: echartsText,
					},
				},
				color: ['#15D0FF', '#FB7E33', '#FFDB15', '#04F5D5'],
				series: [

          
					{
						type: 'pie',
						radius: ['40%', '60%'],
						center: ['25%', '50%'],
						avoidLabelOverlap: false,
						label: {
							show: false,
							position: 'center',
						},
						emphasis: {
							label: {
								show: true,
								fontSize: '14',
								fontWeight: 'bold',
								color: echartsText,
							},
						},
						labelLine: {
							show: false,
						},
						data: [],
					},
				],
			},
		};
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created() {},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted() {
    this.drawEcharts()
  },
	//方法集合
	methods: {
    drawEcharts(){
      let myChart = this.$echarts.init(document.getElementById('EditionCharts'));
      this.optionPie.series[0].data = this.dataList;
      this.isShow = this.dataList && this.dataList.length > 0 ? true : false;
        myChart.setOption(this.optionPie)
        myChart.resize();
				return myChart;
    },
  },
};
</script>
<style lang="scss" scoped>
//@import url(); 引入公共css类
</style>
