<!--  -->
<template>
	<div class="echarts-item">
		<div class="item-header">功能金牌榜</div>
		<div class="item-content" v-if="isShow">
			<div id="FunCharts" :style="{ width: '100%', height: '100%' }"></div>
		</div>
    <div class="noData" v-else>
			<span>--暂无数据--</span>
		</div>
	</div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import {menuDuration} from '@/utils/dictionaries'
const echartsText = '#CBCBCB';
export default {
	name: 'FunctionItem',
	//import引入的组件需要注入到对象中才能使用
	props: {
		dataList: Array,
	},
	components: {},
	data() {
		//这里存放数据
		return {
      isShow: true,
			options: {
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'shadow',
					},
				},
				grid: {
					left: '10%',
					right: '4%',
					bottom: '3%',
					top: '15%',
					containLabel: true,
				},
				xAxis:
					{
						type: 'category',
						data: [],
						axisLabel: {
							textStyle: {
								color: echartsText,
							},
						},
					},
				yAxis:
					{
						type: 'value',
            name: "单位(小时)",
            nameTextStyle: {
            color: "#BCBCBC",
          },
						splitLine: {
							// 是否显示分隔线
							show: true,
							lineStyle: {
								color: '#0F1B2F',
							},
						},
						axisLabel: {
							//是否显示刻度标签文字
							show: true,
							textStyle: {
								color: echartsText,
							},
						},
						axisTick: {
							//是否显示坐标轴刻度
							show: false,
						},
					},
				series: [
					{
						type: 'bar',
						barWidth: 20,
						itemStyle: {
							color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
								{ offset: 0, color: '#FFC000' },
								{ offset: 1, color: '#0B162C' },
							]),
						},
						data: [],
					},
				],
			},
		};
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created() {},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted() {
		this.drawEcharts();
	},
	//方法集合
	methods: {
		drawEcharts() {
			let myChart = this.$echarts.init(document.getElementById('FunCharts'));
			let xData = this.dataList.map((el) => {
				return menuDuration[el.name];
			});
			let yData = this.dataList.map((el) => {
				return (el.value/3600).toFixed(2);
			});
      this.isShow = xData && xData.length > 0 ? true : false;
			this.options.xAxis.data = this.handleSort(
				xData.filter((el, index) => {
					return index < 5;
				})
			);
			this.options.series[0].data = this.handleSort(
				yData.filter((el, index) => {
					return index < 5;
				})
			);
			myChart.setOption(this.options);
      myChart.resize();
				return myChart;
		},

		// 倒序
		handleSort(arr) {
			var temp;
			for (let i = 0; i < arr.length / 2; i++) {
				temp = arr[i];
				arr[i] = arr[arr.length - 1 - i];
				arr[arr.length - 1 - i] = temp;
			}
			return arr;
		},
	},
};
</script>
<style lang="scss" scoped>
//@import url(); 引入公共css类
</style>
