import { request } from "@/utils/request";
import { loading } from "@/utils/index";

// const BASE_PATH = "homePageOverview/all";
class Api { 
  //修改
  @loading()
  async allEchartsData() {
    return request.get(`/homePageOverview/all`);
  }
  //修改
  @loading()
  async appOverview(appId) {
    return request.get(`/homePageOverview/appOverview/${appId}`);
  }
  // 返回项目最多的行业
  @loading()
  async industryMore(appId) {
    return request.get(`/homePageOverview/mostIndustryWithProject/${appId}`);
  }
  // 项目平均单耗
  @loading()
  async industryData(appId, params) {
    return request.get(`/homePageOverview/projectConsumption/${appId}`, {params: params});
  }
  // 项目平均单耗
  @loading()
  async allMap(type,params) {
    return request.get(`/homePageOverview/locationCount/${type}`, {params: params});
  }
}

export default new Api();