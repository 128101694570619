<!--  -->
<template>
	<div class="app-container">
		<div class="all_title"><span></span> 平台企业分布图</div>
		<!-- 地图加载容器 -->
		<div class="platform_map" style="position: relative">
			<el-button size="mini" type="primary" @click="backMap()" class="home-data-chart-l-btn" v-if="flag" style="position: absolute; top: 0; right: 10%; z-index: 9999; height: 30px">返 回</el-button>
			<div class="other_map" id="appMap" style="position: relative"></div>
		</div>
	</div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import $ from 'jquery';
// import 'echarts/map/js/china';
import echarts from 'echarts';
require('@/assets/json/china.js');

import api from '@/api/home';
import Location from '@/assets/json/Location';
import { provinces, cityMap } from '@/utils/dictionaries';
import { getP, getC, getD } from '@/utils/array';
export default {
	name: 'OtherMap',
	//import引入的组件需要注入到对象中才能使用
	components: {},
	data() {
		//这里存放数据
		return {
			flag: false,
		};
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created() {},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted() {
		this.init();
	},
	//方法集合
	methods: {
		// 返回全国视图
		backMap() {
			this.init();
			this.$options.methods.mapChart();
			this.flag = false;
		},
		async init() {
			// 调用接口获取数据
			const data = await api.allMap('CUSTOMER', { appId: '' });
			let chinaArr = Location.districts[0].districts;
			let p = getP(data.data).map((el) => {
				return { coordinate: el.coordinate, adcode: el.province, value: el.origin.length > 0 ? el.origin.length : 0 };
			}); // 省
			let c = getC(data.data).map((el) => {
				return { coordinate: el.coordinate, adcode: el.city, value: el.origin.length > 0 ? el.origin.length : 0 };
			}); // 市
			let d = getD(data.data).map((el) => {
				return { coordinate: el.coordinate, adcode: el.district, value: el.origin.length > 0 ? el.origin.length : 0 };
			}); // 区
			let arrp = [];
			let arrc = [];
			let arrd = [];
			let objp = {};
			let objc = {};
			let objd = {};
			for (const itp of chinaArr) {
				// 省的循环
				p.forEach((elp) => {
					if (itp.adcode === elp.adcode) {
						objp[itp.name] = elp.coordinate;
						arrp.push({
							name: itp.name,
							value: elp.value,
						});
					}
				});
				for (const itc of itp.districts) {
					// 市的循环
					c.forEach((elc) => {
						if (itc.adcode === elc.adcode) {
							objc[itc.name] = elc.coordinate;
							arrc.push({
								name: itc.name,
								value: elc.value,
							});
						}
					});
					for (const itd of itc.districts) {
						// 区的循环
						d.forEach((eld) => {
							if (itd.adcode === eld.adcode) {
								objd[itd.name] = eld.coordinate;
								arrd.push({
									name: itd.name,
									value: eld.value,
								});
							}
						});
					}
				}
			}
			let genCoorMap = Object.assign(objp, objc, objd);
			let mapList = [...arrp, ...arrc, ...arrd];
			this.mapChart(mapList, genCoorMap);
		},

		// 配置渲染map
		mapChart(mapList) {
			let myChart = echarts.init(document.getElementById('appMap'));
			myChart.off('click'); // 这里很重要！！！
			window.addEventListener('resize', () => {
				myChart.resize();
			});
			let that = this;

			initEcharts('china', mapList);
			function initEcharts(map, mapList) {
				// console.log(map,mapList);
				// eslint-disable-next-line no-redeclare
				var mapList = mapList;
				var zoom = false;
				var top = false;
				var left = false;
				if (map == '黑龙江省' || map == '甘肃省') {
					zoom = true;
					top = true;
					left = true;
				} else if (map == '西藏自治区' || map == '内蒙古自治区') {
					top = true;
				} else if (map == '云南省' || map == '福建省' || map == '浙江省' || map == '江西省' || map == '安徽省' || map == '上海市' || map == '河北省' || map == '山西省' || map == '陕西省' || map == '宁夏回族自治区' || map == '天津市' || map == '北京市' || map == '湖南省' || map == '重庆市') {
					left = true;
				} else if (map == '海南省' || map == '福建省' || map == '广东省' || map == '河南省' || map == '山东省' || map == '辽宁省' || map == '新疆维吾尔自治区') {
					zoom = true;
				}
				let option = {
					tooltip: {
						formatter: function (params) {
							if (!params.value) {
								params.value = '-';
							}
							return params.name + '：' + params.value;
						}, //数据格式化
					},
					visualMap: {
						backgroundColor: '#081A37',
						show: true,
						left: 0,
						bottom: 30,
						showLabel: true,
						textStyle: {
							color: '#24CFF4',
						},
            padding: [10, 25],
						pieces: [
							{
								gte: 2001,
								label: '> 2000',
								color: '#0659D2',
							},
							{
								gte: 501,
								lt: 2001,
								label: '501 - 2000',
								color: '#0752BC',
							},
							{
								gte: 201,
								lt: 501,
								label: '201 - 500',
								color: '#084AA9',
							},
							{
								gte: 101,
								lt: 201,
								label: '101 - 200',
								color: '#094395',
							},
							{
								gte: 51,
								lt: 101,
								label: '51 - 100',
								color: '#084295',
							},
							{
								gte: 31,
								lt: 51,
								label: '31 - 50',
								color: '#073271',
							},
							{
								gte: 0,
								lt: 31,
								label: '0 - 30',
								color: '#062A5E',
							},
						],
						inRange: {
							color: ['#780000', '#fb0300', '#e59306', '#d7e573', '#8abda7', '#2396cf'],
						},
					},
					geo: {
						map: map,
						roam: false,
						zoom: zoom ? 0.8 : 1.1,
						top: top ? 120 : 50,
						left: left ? 160 : 100,
						// center: ['103.714129', '38.150339'],
						label: {
							normal: {
								show: true,
								fontSize: '12',
								color: '#bcbcbc',
							},
							emphasis: {
								// 动态展示的样式
								color: '#bcbcbc',
							},
						},

						//地图区域的多边形 图形样式，有 normal 和 emphasis 两个状态
						itemStyle: {
							//normal 是图形在默认状态下的样式；
							normal: {
								borderWidth: 1, // 区域边框宽度
								borderColor: '#00409D', // 区域边框颜色
								areaColor: '#112338', // 区域颜色
							},
							//emphasis 是图形在高亮状态下的样式，比如在鼠标悬浮或者图例联动高亮时。
							emphasis: {
								areaColor: '#0659D2',
								shadowOffsetX: 0,
								shadowOffsetY: 0,
								shadowBlur: 20,
								borderWidth: 0,
								shadowColor: 'rgba(0, 0, 0, 0.5)',
							},
						},
					},
					series: [
						// {
						// 	type: 'effectScatter',
						// 	coordinateSystem: 'geo',
						// 	silent: true,
						// 	data: convertData(mapList),
						// 	symbolSize: function (val) {
						// 		return val[2] / 10;
						// 	},
						// 	showEffectOn: 'render', // 绘制完成后显示特效
						// 	rippleEffect: {
						// 		brushType: 'stroke',
						// 		color: '#FFDB15',
						// 	},
						// 	emphasis: {
						// 		scale: false,
						// 	},
						// 	itemStyle: {
						// 		shadowBlur: 10,
						// 		shadowColor: '#FFDB15',
						// 		color: '#FFDB15',
						// 	},
						// 	zlevel: 1,
						// },
						{
							type: 'map',
							//这里是'china',及因为js中注册的名字，如果是上海市，则该出需pName 指的是'shanghai'
							mapType: map,
							geoIndex: 0,
							data: mapList,
						},
					],
				};
				myChart.setOption(option);
        myChart.resize();
				return myChart;
			}
			// 点击触发
			myChart.on('click', (param) => {
				if (param.name in provinces) {
					// 处理省模块
					let names = param.name;
					for (let key in provinces) {
						if (names == key) {
							showProvince(provinces[key], key);
							break;
						}
					}
				} else if (param.name in cityMap) {
					// 处理市模块
					let names = param.name;
					for (let key in cityMap) {
						if (names == key) {
							showCitys(cityMap[key], key);
							break;
						}
					}
				}
			});

			//展示对应的省
			function showProvince(eName, param) {
				if (param == '台湾省') {
					that.$message.warning('暂无下一级数据');
					return;
				}

				$.getJSON(`/map/province/${eName}.json`, (data) => {
					echarts.registerMap(param, data);
					initEcharts(param, mapList);
					that.flag = true;
					// 获取数据，然后初始化图表
				});
			}

			//展示对应市
			function showCitys(cName, param) {
				// 显示县级地图
				$.getJSON(`/map/city/${cName}.json`, (data) => {
					echarts.registerMap(param, data);
					initEcharts(param, mapList);
					that.flag = true;
				});
			}
		},
	},
};
</script>
<style lang="scss" scoped>
//@import url(); 引入公共css类
</style>
