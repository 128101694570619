<!--  -->
<template>
	<div class="echarts-item">
		<div class="item-header">设备接入数量排名</div>
		<div class="item-content" v-if="isShow">
			<div id="EquCharts" :style="{ width: '100%', height: '100%' }"></div>
		</div>
		<div class="noData" v-else>
			<span>--暂无数据--</span>
		</div>
	</div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
const echartsText = '#CBCBCB';
export default {
	name: 'EquipmentNumberItem',
	props: {
		dataList: Array,
	},
	//import引入的组件需要注入到对象中才能使用
	components: {},
	data() {
		//这里存放数据
		return {
			isShow: true,
			options: {
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'shadow',
					},
				},
				legend: {},
				grid: {
					left: '3%',
					right: '4%',
					bottom: '3%',
					top: '2%',
					containLabel: true,
				},
				xAxis: {
					type: 'value',
					// max: 'dataMax',
					boundaryGap: [0, 0.01],
					splitLine: {
						// 是否显示分隔线
						show: false,
					},
					axisLabel: {
						//是否显示刻度标签
						show: false,
					},
					axisLine: {
						// 是否显示坐标轴轴线
						show: false,
					},
					axisTick: {
						//是否显示坐标轴刻度
						show: false,
					},
				},
				yAxis: {
					type: 'category',
					// max: 5,
					splitLine: {
						// 是否显示分隔线
						show: false,
					},
					minorTick: {
						show: false,
					},
					axisLine: {
						// 是否显示坐标轴轴线
						show: false,
					},
					axisTick: {
						//是否显示坐标轴刻度
						show: false,
					},
					axisLabel: {
						//是否显示刻度标签
						show: true,
						textStyle: {
							color: echartsText,
						},
						formatter: function (value) {
							var ret = ''; //拼接加\n返回的类目项
							var maxLength = 8; //每项显示文字个数
							var valLength = value ? value.length : 1; //X轴类目项的文字个数
							var rowN = Math.ceil(valLength / maxLength); //类目项需要换行的行数
							if (rowN > 1) {
								//如果类目项的文字大于4,
								for (var i = 0; i < rowN; i++) {
									var temp = ''; //每次截取的字符串
									var start = i * maxLength; //开始截取的位置
									var end = start + maxLength; //结束截取的位置
									//这里也可以加一个是否是最后一行的判断，但是不加也没有影响，那就不加吧
									temp = value.substring(start, end) + '\n';
									ret += temp; //凭借最终的字符串
								}
								return ret;
							} else {
								return value;
							}
						},
					},
					data: [],
				},
				series: [
					{
						type: 'bar',
						barWidth: 15,
						showBackground: true,
						backgroundStyle: {
							color: '#062C46',
						},
						itemStyle: {
							emphasis: {},
							normal: {
								color: new this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [
									{ offset: 0, color: '#08223D' },
									{ offset: 1, color: '#00BCF9' },
								]),
							},
						},
						data: [],
					},
				],
			},
		};
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created() {},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted() {
		this.drawEcharts();
	},
	//方法集合
	methods: {
		drawEcharts() {
			let myChart = this.$echarts.init(document.getElementById('EquCharts'));
			let yData = this.dataList.map((el) => {
				return el.name === 'total' ? '设备总数' : el.name;
			}); // y轴为name
			let xData = this.dataList.map((el) => {
				return el.value;
			}); // x轴为value
			this.isShow = xData && xData.length > 0 ? true : false;
			this.options.yAxis.data = this.handleSort(
				yData.filter((el, index) => {
					return index < 6;
				})
			);
			this.options.series[0].data = this.handleSort(
				xData.filter((el, index) => {
					return index < 6;
				})
			);
			myChart.setOption(this.options);
			myChart.resize();
			return myChart;
		},

		// 倒序
		handleSort(arr) {
			var temp;
			for (let i = 0; i < arr.length / 2; i++) {
				temp = arr[i];
				arr[i] = arr[arr.length - 1 - i];
				arr[arr.length - 1 - i] = temp;
			}
			return arr;
		},
	},
};
</script>
<style lang="scss" scoped>
//@import url(); 引入公共css类
</style>
